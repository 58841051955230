import { tv } from 'tailwind-variants'
import classNames from 'classnames'

export const baseTanstackTable = tv({
  slots: {
    container: 'group/container',
    wrapper: 'group/wrapper overflow-auto',
    table: '',
    thead: 'border-b border-gray-750',
    theadTr: 'group/tr bg-gray-850',
    theadTh: 'group/th bg-inherit text-left text-sm font-semibold text-white',
    theadThInner: 'flex items-center justify-between w-full',
    theadThResizer: classNames(
      'absolute',
      'top-0',
      'right-0',
      'h-full',
      'w-1',
      'bg-gray-100/5',
      'cursor-col-resize',
      'select-none',
      'touch-none',
      'data-[resizing=true]:bg-primary',
      'data-[resizing=true]:bg-opacity-100',
      'transition-colors',
      'duration-100',
      'hover:bg-primary',
      'after:absolute',
      'after:left-1/2',
      'after:-translate-x-1/2',
      'after:top-5',
      'after:bottom-0',
      'hover:after:bg-black',
      'after:-translate-y-1/2',
      'after:bg-gray-700',
      'after:w-px',
      'after:before:bg-black',
      'after:transition-colors',
      'after:duration-100',
      'before:pointer-events-none-',
      'before:absolute',
      'before:bg-transparent',
      'before:right-0',
      'before:w-full',
      'before:top-0',
      'before:h-[var(--table-col-resize-height)]',
      'before:transition-colors',
      'before:duration-100',
      'hover:before:bg-primary',
      'data-[resizing=true]:after:bg-primary',
      'data-[resizing=true]:before:bg-primary',
    ),
    tbody: 'divide-y divide-gray-800',
    tbodyTr: 'group/tr transition-colors duration-100',
    tbodyTd: 'group/td bg-inherit text-left text-sm',
    tfoot: '',
    tfootTr: 'group/tr bg-gray-900',
    tfootTh: 'group/th bg-inherit text-left text-sm font-semibold text-white',
    tfootThInner: 'flex items-center w-full',
  },
  variants: {
    type: {
      table: {
        container: 'w-full',
        wrapper: 'w-full',
        table: 'w-full',
      },
      grid: {
        table: 'grid',
        thead: 'grid',
        theadTr: 'flex w-full',
        theadTh: 'flex relative',
        tbody: 'grid relative',
        tbodyTr: 'flex absolute w-full',
        tbodyTd: 'flex items-center',
        tfoot: 'grid sticky bottom-0',
        tfootTr: 'flex w-full',
        tfootTh: 'flex relative',
      },
    },
    hoverable: {
      true: {
        tbodyTr: 'hover:bg-gray-800',
      },
    },
    highlight: {
      true: {
        tbodyTr: '!bg-primary text-gray-900',
      },
    },
    clickable: {
      true: {
        tbodyTr: 'cursor-pointer',
      },
    },
    sticky: {
      true: {
        thead: 'sticky z-10',
      },
    },
    hasScroller: {
      true: {
        wrapper: 'md:overscroll-x-none lg:scrollbar:w-0 lg:scrollbar:h-0',
      },
    },
    select: {
      true: undefined,
    },
    stripedRows: {
      true: {
        tbodyTr:
          'data-[odd=true]:bg-gray-850 data-[even=true]:bg-gray-800 peer',
        tbodyTd: 'peer-data-[odd=true]:text-red-500',
      },
      false: {
        tbodyTr: 'bg-gray-850',
      },
    },
    showGridlines: {
      true: {
        table: 'border-separate border-spacing-0',
      },
    },
    padding: {
      sm: {
        theadTh: 'px-2 py-1',
        tbodyTd: 'px-2 py-1',
        tfootTh: 'px-2 py-1',
      },
      md: {
        theadTh: 'px-4 py-2.5',
        tbodyTd: 'px-4 py-2.5',
        tfootTh: 'px-4 py-2.5',
      },
      lg: {
        theadTh: 'px-6 py-3.5',
        tbodyTd: 'px-6 py-3.5',
        tfootTh: 'px-6 py-3.5',
      },
      none: undefined,
    },
    rounded: {
      true: {
        base: 'rounded-lg',
        theadTr: classNames(
          '[&>th:first-child]:overflow-hidden',
          '[&>th:first-child]:rounded-tl-lg',
          '[&>th:last-child]:overflow-hidden',
          '[&>th:last-child]:rounded-tr-lg',
        ),
      },
    },
    columnCanSort: {
      true: {
        theadThInner: 'cursor-pointer select-none max-w-full',
      },
    },

    groupHasData: {
      true: undefined,
      false: undefined,
    },

    isGroup: {
      true: {
        theadTh: 'text-center text-gray-400',
        theadThResizer:
          'before:hidden after:hidden !bg-transparent !pointer-events-none',
      },
      false: undefined,
    },

    hasParent: {
      true: {
        theadTh: 'bg-primary/5 text-white',
      },
      false: undefined,
    },

    isLastLeftPinnedColumn: {
      true: {
        tbodyTd: classNames(
          'group-data-[arrived-left=false]/wrapper:before:pointer-events-none',
          'group-data-[arrived-left=false]/wrapper:before:absolute',
          'group-data-[arrived-left=false]/wrapper:before:inset-0',
          'group-data-[arrived-left=false]/wrapper:before:border-r',
          'group-data-[arrived-left=false]/wrapper:before:border-gray-750/20',
          'group-data-[arrived-left=false]/wrapper:before:shadow-[5px_0px_5px_0px_#00000024]',
          "group-data-[arrived-left=false]/wrapper:before:content-['']",
        ),
      },
    },
    isFirstRightPinnedColumn: {
      true: {
        tbodyTd: classNames(
          'group-data-[arrived-right=false]/wrapper:before:pointer-events-none',
          'group-data-[arrived-right=false]/wrapper:before:absolute',
          'group-data-[arrived-right=false]/wrapper:before:inset-0',
          'group-data-[arrived-right=false]/wrapper:before:border-l',
          'group-data-[arrived-right=false]/wrapper:before:border-gray-750/20',
          'group-data-[arrived-right=false]/wrapper:before:shadow-[-5px_0px_5px_0px_#00000024]',
          "group-data-[arrived-right=false]/wrapper:before:content-['']",
        ),
      },
    },
    hasFooter: {
      true: undefined,
      false: undefined,
    },
    isSubRow: {
      true: {
        tbodyTd: 'px-0 py-0 w-full',
      },
    },
    isGroupHeader: {
      true: {
        theadTh: '!z-10',
      },
    },
  },
  compoundVariants: [
    {
      isGroup: true,
      groupHasData: true,
      class: {
        theadTh:
          '-ml-px !border-b-0 border-l !border-gray-800 bg-primary text-gray-900 !py-1',
      },
    },
    {
      isGroup: true,
      groupHasData: false,
      class: {
        theadTh: '!border-t-transparent !border-x-0 bg-gray-825 border-0',
      },
    },
    {
      hoverable: true,
      stripedRows: true,
      class: {
        tbodyTr:
          'data-[odd=true]:hover:bg-gray-750 data-[even=true]:hover:bg-gray-750',
      },
    },
    {
      showGridlines: true,
      class: {
        theadTh: 'border-y border-r border-gray-750 first:border-l',
        tfootTh: 'border-y border-r border-gray-850 first:border-l',
      },
    },
    {
      select: true,
      virtualScroller: true,
      class: {
        theadTh: 'flex items-center justify-center',
        tbodyTd: 'flex items-center justify-center',
        tfootTh: 'flex items-center justify-center',
      },
    },
    {
      rounded: true,
      hasFooter: false,
      class: {
        tbody: classNames(
          '[&>tr:last-child>td:first-child]:overflow-hidden',
          '[&>tr:last-child>td:first-child]:rounded-bl-lg',
          '[&>tr:last-child>td:last-child]:overflow-hidden',
          '[&>tr:last-child>td:last-child]:rounded-br-lg',
        ),
      },
    },
    {
      rounded: true,
      hasFooter: true,
      class: {
        tfoot: classNames(
          '[&>th:first-child]:overflow-hidden',
          '[&>th:first-child]:rounded-bl-lg',
          '[&>th:last-child]:overflow-hidden',
          '[&>th:last-child]:rounded-br-lg',
        ),
      },
    },
    {
      isSubRow: false,
      showGridlines: true,
      class: {
        tbodyTd: 'border-b border-r border-gray-750 first:border-l',
      },
    },
  ],
  defaultVariants: {
    isSubRow: false,
  },
})
